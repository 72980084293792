import React, { Fragment, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from "react-redux";
import { getDevice } from "../../action/report";
import Alert from "../layout/Alert";
import M from "materialize-css/dist/js/materialize.min.js";
import LineTestUserDeviceDetails from './DeviceDetails'
import customConfig from "../../config";
import BarcodeScanner from "../Common/scanner/BarcodeScanner";
import { getQRDetailsUsingScanner } from "../../action/qrcode";
import { setAlert } from "../../action/alert";
import ScannerGunIcon from './../../assets/scanner_gun_icon.png'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const useStyles = makeStyles({
  selectInput: {
    height: "3rem",
    width: "100%"
  },
  selectValues: {
    width: "100%",
  },
  activeTab: {
    border: '1px solid #bdbdbd',
    backgroundColor: '#e0e0e0',
    textTransform: 'capitalize'
  },
  nonActiveTab: {
    border: 'none',
    backgroundColor: 'none',
    textTransform: 'capitalize'
  },
});

const LineTestUserSearchDevice = ({
  setAlert,
  deviceId,
  getDevice,
  DeviceData: {
    device
  },
  wrapperClass = deviceId ? "" : "right-content-wraper",
  userRoleInfo,
  user: { user },
  tenant: { tenantInfo },
}) => {
  const classes = useStyles()
  const [activeTab, setActiveTab] = React.useState(2)

  const history = useHistory();
  const [text, setText] = useState("");
  const [showText, setShowText] = useState(false);
  const [usbBasedScannerInputField, setUsbBasedScannerInputFiled] = useState("")
  const usbBasedScannerInput = useRef(null)
  const [scanningInProgress, setScanningInProgress] = useState(false)
  const [qrScanningError, setQRScanningError] = useState(false)

  useEffect(() => {
    M.AutoInit();
  }, []);

  useEffect(() => {
    if (deviceId) {
      setText(deviceId);
      setShowText(false);
      device = null;
      getDevice(deviceId, customConfig.deviceSearchByDeviceId, userRoleInfo, tenantInfo.tenantId, false);
    }
    else {
      if (!showText && history.location.state) {
        setShowText(true);
      }
    }
  }, []);

  useEffect(() => {
    if (text.length > 0 && device && device.length > 0) {
      setShowText(true);
      setText("");
      setUsbBasedScannerInputFiled("")
      setActiveTab(2)
      setScanningInProgress(false)
    }
    else if (text.length > 0 && device && !Array.isArray(device)) {
      // there is some error while getting the device details
      setShowText(true);
      setText("");
      setUsbBasedScannerInputFiled("")
      setActiveTab(2)
      setScanningInProgress(false)
    }
  }, [device]);

  useEffect(() => {

    if (activeTab == 1) {
      usbBasedScannerInput.current.focus()
      setShowText(false);
      setText("");
    }
    else if (activeTab == 0) {
      setShowText(false);
      setText("");
    }
  }, [activeTab])

  function handleQRCodeScanDetails(data) {
    setShowText(false);
    device = null;
    setScanningInProgress(true)
    setQRScanningError(false)

    if (data.toString().substring(0, 2) == "MT") {
      if (data.substring(0, 3) == "MT;") {
        data = data.replace("MT;", "MT:")
      }

      getQRDetailsUsingScanner(data)
        .then((qrCodeScannedResult) => {
          if (qrCodeScannedResult.productSerialNumber) {
            setText(qrCodeScannedResult.productSerialNumber)
            getDevice(qrCodeScannedResult.productSerialNumber, customConfig.deviceSearchByProductSerialNumber, userRoleInfo, tenantInfo.tenantId, false)
          }
          else {
            setAlert("Cannot Scan QR Code. Scanned QR Code is Model ID Based QR Code", "danger")
            setShowText(true);
            setScanningInProgress(false)
            setActiveTab(2)
            setUsbBasedScannerInputFiled("")
            setQRScanningError(true)
          }
        })
        .catch((error) => {
          setAlert("Failed to Scan QR Code", "danger")
          setShowText(true);
          setScanningInProgress(false)
          setActiveTab(2)
          setUsbBasedScannerInputFiled("")
          setQRScanningError(true)
        })
    }
    else {
      setText(data)
      getDevice(data, customConfig.deviceSearchByProductSerialNumber, userRoleInfo, tenantInfo.tenantId, false);
    }
  }

  return (
    <Fragment>
      <div className={wrapperClass}>
        <div className="container">
          <div className="row">

            <div className="s12 col">
              <div className="content-block bottom-shadow search-layout-2 mb-0">
                <div className="p-15">
                  <div className="row srch-device-reset mb-0">
                    <div className="s3 col">
                      <div className="page-title">
                        <label className="black-text font-600" style={{ lineHeight: '60px' }}>
                          Search Device Using Webcam or USB Scanner
                        </label>
                      </div>
                    </div>
                    <div className="s4 col">
                      <Tabs
                        value={activeTab}
                        onChange={(e, newValue) => {
                          setActiveTab(newValue)
                        }}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#bdbdbd",
                            float: 'right'
                          }
                        }}
                        style={{ marginTop: '20px' }}
                      >
                        <Tab
                          label={
                            activeTab == 0 && scanningInProgress ? "Scanning In Progress" : "Scan Using Webcam Scanner"
                          }
                          icon={<i class="fa fa-qrcode fa-3x"></i>}
                          className={activeTab == 0 ? classes.activeTab : classes.nonActiveTab}
                          title="Click to Scan QR Code or Barcode Using Webcam"
                          style={{ border: '1px solid black', marginRight: '10px' }}
                          disabled={scanningInProgress ? true : false}
                        />
                        <Tab
                          label={
                            activeTab == 1 && scanningInProgress ? "Scanning In Progress" : "Scan Using USB Scanner"
                          }
                          icon={<img src={ScannerGunIcon} style={{ width: '40px', height: "40px", cursor: "pointer" }} />}
                          className={activeTab == 1 ? classes.activeTab : classes.nonActiveTab}
                          title="Click to Scan QR Code or Barcode Using USB Scanner"
                          style={{ border: '1px solid black', marginRight: '10px' }}
                          disabled={scanningInProgress ? true : false}
                        />
                      </Tabs>
                    </div>
                    {
                      activeTab == 0 && !scanningInProgress && <Fragment>
                        <div className="s4 col">
                          <BarcodeScanner scanDetailsHandler={handleQRCodeScanDetails}></BarcodeScanner>
                          <label>QR Code or Barcode Scanner</label>
                        </div>
                      </Fragment>
                    }
                    {
                      activeTab == 1 && <Fragment>
                        <div className="s4 col" style={{ width: '0px', height: '0px' }}>
                          <input
                            ref={usbBasedScannerInput}
                            value={usbBasedScannerInputField}
                            type="text"
                            style={{
                              opacity: 0,
                              width: 0,
                              height: '0px !important'
                            }}
                            onChange={(e) => {
                              setUsbBasedScannerInputFiled(e.target.value)
                              clearTimeout(window.focusTimeout)
                              window.focusTimeout = setTimeout(() => {
                                if (e.target.value && e.target.value.length > 0) {
                                  handleQRCodeScanDetails(e.target.value, true)
                                }
                              }, 500)
                            }}
                            onBlur={(e) => {
                              //focus the text field again if tab is active and data is not yet received
                              if (activeTab == 1) {
                                e.target.focus()
                              }
                            }}
                          />
                        </div>
                      </Fragment>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mid-seprator"></div>
          <Alert />
        </div>
      </div>
      <div>
        <div
          style={{ marginTop: "0px", paddingTop: "0px" }}
          className={wrapperClass}
        >
          <div className="container">
            <div className="row mb-0">
              <div className="s12 p-5 col">
                <div className="content-block position-relative">
                  <div className="p-15">
                    <label className="black-text font-600" style={{ lineHeight: '60px' }}>
                      Scanning Status  :
                    </label>
                    <label style={{ color: 'black', marginLeft: '20px' }}>
                      {
                        activeTab == 0 && scanningInProgress && "Webcam Scanning In Pogress"
                      }
                      {
                        activeTab == 0 && !scanningInProgress && "Webcam Scanning Started"
                      }
                      {
                        activeTab != 0 && !scanningInProgress && showText && "Scanning Completed. Please Click Above Button To Start Scanning Again"
                      }
                      {
                        activeTab == 1 && scanningInProgress && "USB Scanning In Pogress"
                      }
                      {
                        activeTab == 1 && !scanningInProgress && "USB Scanning Started"
                      }
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {showText && !qrScanningError && device && Array.isArray(device) && device.length > 0 && (
          <div
            style={{ marginTop: "0px", paddingTop: "0px" }}
            className={wrapperClass}
          >
            <div className="container">
              <div className="row mb-0">
                <div className="s12 p-0 col">
                  <div className="content-block position-relative" style={{ backgroundColor: 'green', padding: '25px' }}>
                    <div className="p-25">
                      <p style={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'center', color: 'white' }}>Line Test Passed</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="device-detail-wrap device-info">
                <div className="row mb-0">
                  <div className="s12 p-0 col">
                    <div className="content-block position-relative">
                      <div className="p-15">
                        <LineTestUserDeviceDetails
                          device={device}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {showText &&  !qrScanningError && (device && !Array.isArray(device)) && (
          <div
            style={{ marginTop: "0px", paddingTop: "0px" }}
            className={wrapperClass}
          >
            <div className="container">
              <div className="row mb-0">
                <div className="s12 p-5 col">
                  <div className="content-block position-relative" style={{ backgroundColor: 'red', padding: '25px' }}>
                    <div className="p-15">
                      <p style={{ fontSize: '22px', fontWeight: 'bold', textAlign: 'center', color: 'white' }}>Line Test Failed  - {device.msg}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  DeviceData: state.report,
  UserData: state.user,
  userRoleInfo: {
    level: state.auth.userLevel,
    roles: state.auth.userRoles,
  },
  user: state.user,
  tenant: state.tenant,
});

const mapDispatchToProps = (dispatch) => ({
  getDevice: (arg1, arg2, arg3, arg4, arg5, arg6) => dispatch(getDevice(arg1, arg2, arg3, arg4, arg5, arg6)),
  setAlert: (message, status) => dispatch(setAlert(message, status)),
})

export default connect(mapStateToProps, mapDispatchToProps)(LineTestUserSearchDevice);
