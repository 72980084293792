import React, { Fragment } from "react";
import PropTypes from "prop-types";
import NavWrapper from "../../Common/user/NavWrapper";
import { Link, useLocation } from "react-router-dom";
import customConfig from "../../../config";

const NavBar = (props) => {
  const location = useLocation();
  return (
    <NavWrapper>
      {({ auth, isAuthenticated, tenantLogoUrl, onLogout, tenantInfo }) => {
        const userInfo = {
          level: auth.userLevel,
          roles: auth.roles
        };
        return (
          <div className="header mb-30">
            <div className="blue-bar-wrap default-header-bg d-flex align-items-center  py-20">
              <div className="d-flex justify-content-between w-full align-items-center ">
                <div className="px-20 logo">
                  <img src={tenantLogoUrl} />
                </div>
                <div className="d-flex justify-content-end align-items-center ">
                  <ul className="top-right-link">
                    <li>
                      <a
                        href={customConfig.supportURL}
                        className="d-flex"
                        target="_blank"
                      >
                        <i className="icn-support"></i>
                        <label
                          style={{ cursor: "pointer", display: "inline-block" }}
                        >
                          Support
                        </label>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex" onClick={onLogout}>
                        <label
                          style={{ cursor: "pointer", display: "inline-block" }}
                        >
                          Logout
                        </label>
                      </a>
                    </li>
                    <li>
                      <a href="#" className="d-flex">
                        <label>{auth.username}</label>{" "}
                        {/* <i className="icn-username"></i> */}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="top-menu-wrapper bottom-shadow-1 mb-30">
              <div className="row">
                <div className="col s12">
                  <div className="d-flex justify-content-center align-items-center">
                    <ul className="top-navigation">
                      {
                        (userInfo.roles.includes(customConfig.roleLineTestUser)) ?
                          <Link
                            to="/dashboard"
                            className={
                              location.pathname === "/dashboard" ||
                                location.pathname === "/dashboardBack"
                                ? "active"
                                : ""
                            }
                          >
                            <i className="left-icon device-info"></i>Device Info
                          </Link>
                          :
                          <Fragment>
                            {
                              (userInfo.level == 0 && (userInfo.roles.includes(customConfig.roleFactoryAdmin))) ||
                                userInfo.roles.includes(customConfig.roleFieldOperator) ?
                                <li>
                                  <Link
                                    to="/dashboard"
                                    className={
                                      location.pathname === "/dashboard" ||
                                        location.pathname === "/dashboardBack"
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <i className="left-icon user-info"></i>Device Info
                                  </Link>
                                </li>
                                :
                                <li>
                                  <Link
                                    to="/dashboard"
                                    className={
                                      location.pathname === "/dashboard" ||
                                        location.pathname === "/dashboardBack"
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <i className="left-icon user-info"></i>User Info
                                  </Link>
                                </li>
                            }
                            {
                              userInfo.level > 0 && (!userInfo.roles.includes(customConfig.roleFieldOperator)) && <li>
                                <Link
                                  to="/searchDevice"
                                  className={
                                    location.pathname === "/searchDevice"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <i className="icon device-info"></i>Device Info
                                </Link>
                              </li>
                            }
                            {
                              (userInfo.level > 0 || userInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                              <li>
                                <Link
                                  to="/deviceDetail"
                                  className={
                                    location.pathname.includes("/deviceDetail")
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <i className="icon device-model"></i>Device Model
                                </Link>
                              </li>
                            }
                            {(userInfo.roles?.includes("FACTORYADMIN") || userInfo.roles?.includes("CUSTOMERSUPPORTL4") || userInfo.roles?.includes("CLOUDADMIN")) && <li>
                              <Link
                                to="/DACStatistics"
                                className={
                                  location.pathname.indexOf("/DACStatistics") !== -1
                                    ? "active"
                                    : ""
                                }
                              >
                                <i className="left-icon device-info"></i>
                                DAC Statistics
                              </Link>
                            </li>}
                            {(userInfo.level > 4) && (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                              (<li>
                                <Link
                                  to="/deviceFirmware"
                                  className={
                                    location.pathname.indexOf("/deviceFirmware") !== -1
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <i className="left-icon device-model"></i>
                                  Device Firmware
                                </Link>
                              </li>)}
                            {((userInfo.level > 3 && userInfo.roles.includes(customConfig.roleCloudAdmin))
                              || userInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                              <li>
                                <Link
                                  to="/idueepromConfig"
                                  className={
                                    location.pathname.indexOf("/idueepromConfig") !== -1
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <i className="left-icon device-model"></i>
                                  IDU EEPROM Config
                                </Link>
                              </li>
                            }
                            {(userInfo.level > 2 && (tenantInfo?.tenantId === "panasonic") &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator))) &&
                              <li>
                                <Link
                                  to="/statistics"
                                  className={
                                    location.pathname === "/statistics"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <i className="icon statistics"></i>Statistics
                                </Link>
                              </li>
                            }
                            {(userInfo.level > 2 && (tenantInfo?.tenantId === "panasonic") &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator))) &&
                              <li>
                                <Link
                                  to="/productMetrics"
                                  className={
                                    location.pathname === "/productMetrics"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <i className="icon metrics"></i>Product Metrics
                                </Link>
                              </li>
                            }
                            {userInfo.level > 4 &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                              <li>
                                <Link
                                  to="/fotaJobs"
                                  className={
                                    location.pathname === "/fotaJobs" ? "active" : ""
                                  }
                                >
                                  <i className="icon mqtt-fota"></i>Manage MQTT FOTA
                                </Link>
                              </li>
                            }
                            {/* {userInfo.level > 2 &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                              <li>
                                <Link
                                  to="/firmwareInfo"
                                  className={
                                    location.pathname === "/firmwareInfo"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <i className="icon mqtt-fota"></i>Firmware Info
                                </Link>
                              </li>
                            } */}
                            {(userInfo.level > 4 ||
                              userInfo.roles.includes(customConfig.roleCloudAdmin) ||
                              userInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                              <li>
                                <Link
                                  to="/manufacturedDevices"
                                  className={
                                    location.pathname === "/manufacturedDevices"
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <i className="icon device-model"></i>Manufactured Devices
                                </Link>
                              </li>
                            }
                            {userInfo.level > 3 &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                              <li>
                                <Link
                                  to="/feedback"
                                  className={
                                    location.pathname.indexOf("/feedback") !== -1 ? "active" : ""
                                  }
                                >
                                  <i className="icon mqtt-fota"></i>
                                  Feedback
                                </Link>
                              </li>
                            }
                            {/* {userInfo.level > 3 &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                              <li>
                                <Link
                                  to="/metricsChart"
                                  className={
                                    location.pathname.indexOf("/metricsChart") !== -1 ? "active" : ""
                                  }
                                >
                                  <i className="icon metrics-chrt"></i>
                                  Metrics Chart
                                </Link>
                              </li>
                            } */}
                            {
                              (userInfo.level > 4 ||
                                userInfo.roles.includes(customConfig.roleCloudAdmin) ||
                                userInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                              <li>
                                <Link
                                  to="/qrcode"
                                  className={
                                    location.pathname.indexOf("/qrcode") !== -1
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <i className="left-icon device-model"></i>
                                  QR Code
                                </Link>
                              </li>
                            }
                            {
                              (userInfo.level > 4 || userInfo.roles.includes(customConfig.roleCloudAdmin) ||
                                userInfo.roles.includes(customConfig.roleFactoryAdmin)) &&
                              (!userInfo.roles.includes(customConfig.roleFieldOperator)) &&
                              <li>
                                <Link
                                  to="/deviceDealer"
                                  className={
                                    location.pathname.indexOf("/deviceDealer") !== -1
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <i className="left-icon device-model"></i>
                                  Dealer Management
                                </Link>
                              </li>
                            }
                          </Fragment>
                      }
                      {userInfo.level >= 2 && <li>
                        <Link
                          to="/notification"
                          className={
                            location.pathname.indexOf("/notification") !== -1
                              ? "active"
                              : ""
                          }
                        >
                          <i className="left-icon device-model"></i>
                          Notification Management
                        </Link>
                      </li>}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    </NavWrapper>
  );
};

NavBar.propTypes = {};


export default NavBar;
