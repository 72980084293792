import React, { Fragment, useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
// import { generateQRCode, fetchQRCode } from "../../action/qrcode";
// import { getDeviceModelNumbersList } from './../../action/addvcModel'
// import { useReactToPrint } from 'react-to-print';
import Alert from "../layout/Alert";
// import Radio from '@material-ui/core/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import DatePicker from "react-datepicker";
import PushNotification from "./pushNotification";
import UserSelection from "./userSelection";
import ScheduleNotification from "./scheduleNotification";
import { notificationChannelTypes, notificationConstants, notificationCountryMapping, notificationDetailsRefreshRate, notificationStatusHeaders } from './notificationConstant'
import EmailNotification from "./emailNotification";
import SMSNotification from "./smsNotification";
import { format } from "date-fns";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});

const headers = {
    productSerialNumber: "Product Serial Number",
    status: "QR Code Print Status",
    reasonOfFailure: "Reason of Failure"
};

const tableHeaders = notificationStatusHeaders
const SendNotification = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    allowedCountries,
    setAlert,
    notificationStore: { sendNotificationInProgress, lastNotificationDetails },
    createNotificationTask,
    getNotificationTask,
    setDialog,
    setDialogContents,
    setDialogTab,
    userLevel
}) => {
    const classes = useStyles()
    const [channelType, setChannelType] = useState("")
    const [expiryDate, setExpiryDate] = useState(undefined)
    const [pnPlatforms, setPnPlatforms] = useState({
        iosPlatform: true,
        androidPlatform: true
    })
    const [severity, setSeverity] = useState("")
    const [fetchNotificationTimeout, setFetchNotificationTimeout] = useState(null);

    // push notification message
    const [pnMessage, setPnMessage] = useState("");
    const [emailNotificationData, setEmailNotificationData] = useState({ text: "", subject: "", html: "" })
    const [smsNotificationData, setSmsNotificationData] = useState("");
    const [copied, setCopied] = useState(false);

    //user selection criteria
    //const [countries, setCountries] = useState([])
    const [uploadedUserIdFileInput, setUploadedUserIdFileInput] = useState("")
    const userIdCSVFileInputRef = useRef()
    const [uploadedUserIdListFromCSV, setUploadedUserIdListFromCSV] = useState([])
    const [country, setCountry] = useState("None");
    const [deviceOwnership, setDeviceOwnership] = useState([])
    const [userStartLastLogin, setUserStartLastLogin] = useState(undefined)
    const [userEndLastLogin, setUserEndLastLogin] = useState(undefined)
    const [notificationTaskData, setNotificationTaskData] = useState(null)

    // schedule notifications
    const [scheduleNotification, setSheduleNotification] = useState(false)
    const [scheduleType, setScheduleType] = useState(notificationConstants.oneTimeScheduleType)
    const [multiScheduleDateTime, setMultiScheduleDateTime] = useState([])
    const [scheduleDayOfWeek, setScheduleDayOfWeek] = useState([])
    const [multiScheduleTime, setMultiScheduleTime] = useState([])

    //const [createNotificationTaskInProgress, setCreateNotificationTaskInProgress] = useState(false)

    // Adjust the height of menu items in select. Otherwise get hidden behind headers
    const menuProps = {
        style: {
            marginTop: '52px'
        },
    };


    function onChangePushNotificationPlaformType(platformType, checkedStatus) {
        setPnPlatforms((prevState) => {
            return {
                ...prevState,
                [platformType]: checkedStatus
            }
        });
    }

    // push notification handler functions
    function onChangePushNotificationMessageData(messageData) {
        setPnMessage(messageData)
    }

    // user handler functions
    function onChangeUserCountry(userCountriesData) {
        setCountry(userCountriesData)
    }
    function onChangeUserDeviceOwnership(deviceOwnershipData) {
        setDeviceOwnership(deviceOwnershipData)
    }
    function onChangeUserStartLastLogin(userLastLoginData) {
        setUserStartLastLogin(userLastLoginData)
    }
    function onChangeUserEndLastLogin(userLastLoginData) {
        setUserEndLastLogin(userLastLoginData)
    }
    function onChangeUserIdListFileInput(fileData) {
        setUploadedUserIdListFromCSV([])
        setUploadedUserIdFileInput(fileData)
    }

    // scheduling functions
    function onChangeScheduleNotification(scheduleNotificationData) {
        setSheduleNotification(scheduleNotificationData)
    }
    function onChangeScheduleType(notificationScheduleType) {
        setScheduleType(notificationScheduleType)
    }
    function onChangeMultiScheduleDateTime(scheduleDateTimeData) {
        setMultiScheduleDateTime(scheduleDateTimeData)
    }
    function onChangeScheduleDayOfWeek(dayOfWeekData) {
        setScheduleDayOfWeek(dayOfWeekData)
    }
    function onChangeMultiScheduleTime(scheduleTimeData) {
        if (multiScheduleTime && multiScheduleTime.length == 0) {
            setMultiScheduleTime([scheduleTimeData])
        }
        else {
            setMultiScheduleTime([...multiScheduleTime, scheduleTimeData])
        }
    }
    function onDeleteMultiScheduleTime(indextoDelete) {
        multiScheduleTime.splice(indextoDelete, 1)
        setMultiScheduleTime([...multiScheduleTime])

    }

    // general logic functions

    function parseUploadedUserIdFile() {
        return new Promise((resolve, reject) => {
            // parse uploaded file to get the list of product serial numbers
            const reader = new FileReader();
            reader.onload = function (event) {
                let textData = event.target.result

                let arrFileData = csvToArray(textData)
                if (arrFileData.length == 0) {
                    setAlert("Empty File of User Ids Uploaded", "danger")
                    resolve([])
                }

                arrFileData = arrFileData.map((element) => {
                    return Object.values(element)[0].replace(/(\r\n|\n|\r)/gm, '')
                })
                //setUploadedUserIdListFromCSV(arrFileData)
                resolve(arrFileData)
            };

            reader.readAsText(uploadedUserIdFileInput);
        })

    }

    function csvToArray(str, delimiter = ",") {
        const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
        const rows = str.slice(str.indexOf("\n") + 1).split("\n");
        if (rows && rows.length > 0) {
            if (rows[rows.length - 1] == "") {
                rows.splice(-1) //removing the last element with empty values from array
            }
        }
        if (!rows || rows.length == 0) {
            return []
        }
        const arr = rows.map(function (row) {
            const values = row.split(delimiter);
            const el = headers.reduce(function (object, header, index) {
                object[header] = values[index];
                return object;
            }, {});
            return el;
        });
        return arr;
    }

    function handleCreateNotificationTaskReset() {
        setChannelType("");
        setExpiryDate(null);
        setSeverity('');
        setPnMessage("");
        setEmailNotificationData({ text: "", subject: "", html: "" });
        setSmsNotificationData("");
        setUploadedUserIdFileInput("");
        setCountry("None");
        setDeviceOwnership([]);
        setUserEndLastLogin(null);
        setUserStartLastLogin(null);
        setSheduleNotification(false)
        setScheduleType(notificationConstants.oneTimeScheduleType)
        setMultiScheduleDateTime([])
        setScheduleDayOfWeek([])
        setMultiScheduleTime([])
        window.scrollTo(0, 0);
    }

    function handleCreateNotificationTask() {

        let notificationTaskRequest = {}

        //construct channel payload
        if (!channelType || channelType == "") {
            setAlert("Inavlid Channel Type. Please select a valid channel type", "danger")
            window.scrollTo(0, 0)
            return
        }
        notificationTaskRequest["channel"] = {}
        notificationTaskRequest["channel"]["type"] = channelType;
        if (channelType === notificationChannelTypes?.pushNotification?.value) {
            if (!pnMessage || pnMessage == "") {
                setAlert("Push notification message cannot be empty", "danger")
                window.scrollTo(0, 0)
                return
            }
            let msg = null;
            try {
                msg = JSON.parse(pnMessage)
            }
            catch (err) {
                setAlert("Push notification message should be valid JSON", "danger")
                window.scrollTo(0, 0)
                return
            }
            //construct the message payload - vary for each notification type
            notificationTaskRequest["message"] = {}
            notificationTaskRequest["message"]["payload"] = msg
            if (pnPlatforms['iosPlatform'] == true) {
                if (!notificationTaskRequest["channel"].hasOwnProperty("platform")) {
                    notificationTaskRequest["channel"]["platform"] = []
                }
                notificationTaskRequest["channel"]["platform"].push('ios')
            }
            if (pnPlatforms['androidPlatform'] == true) {
                if (!notificationTaskRequest["channel"].hasOwnProperty("platform")) {
                    notificationTaskRequest["channel"]["platform"] = []
                }
                notificationTaskRequest["channel"]["platform"].push('an')
            }

        }
        else if (channelType === notificationChannelTypes?.smsNotification?.value) {
            if (!smsNotificationData || smsNotificationData === "") {
                setAlert("SMS notification message cannot be empty", "danger")
                window.scrollTo(0, 0)
                return
            }
            notificationTaskRequest["message"] = {}
            notificationTaskRequest["message"]["text"] = smsNotificationData;
        }
        else if (channelType === notificationChannelTypes?.emailNotification?.value) {
            if (!emailNotificationData?.subject || emailNotificationData?.subject === "") {
                setAlert("Email notification subject cannot be empty", "danger")
                window.scrollTo(0, 0)
                return
            }
            else if (emailNotificationData?.text === "" && emailNotificationData?.html === "") {
                setAlert("Email notification requires text or html content.", "danger")
                window.scrollTo(0, 0)
                return
            }
            notificationTaskRequest["message"] = {}
            notificationTaskRequest["message"]["text"] = emailNotificationData?.text;
            notificationTaskRequest["message"]["html"] = emailNotificationData?.html;
            notificationTaskRequest["message"]["subject"] = emailNotificationData?.subject;
        }

        // check for severity
        if (!severity || severity.length == "") {
            setAlert("Inavlid Severity Type. Please Select Severity", "danger")
            window.scrollTo(0, 0)
            return
        }
        notificationTaskRequest["severity"] = severity


        //construct users payload
        if ((!uploadedUserIdFileInput || uploadedUserIdFileInput == "") && (!country || country.length == 0 || country == "None") && (!deviceOwnership || deviceOwnership.length == 0) &&
            (!userStartLastLogin) && (!userEndLastLogin)) {
            setAlert("Please Select Any of User Ids, Country, Device Ownership, Start or End Login Date", "danger")
            window.scrollTo(0, 0)
            return
        }
        notificationTaskRequest["users"] = {}
        if (country && country != "" && country !== "None") {
            notificationTaskRequest["users"]["country"] = country?.toLowerCase()
            // country is an optional param
        }
        if (deviceOwnership && deviceOwnership.length > 0) {
            notificationTaskRequest["users"]["categories"] = [...deviceOwnership]
        }
        if (userStartLastLogin) {
            if (!notificationTaskRequest["users"].hasOwnProperty("lastLogin")) {
                notificationTaskRequest["users"]["lastLogin"] = {}
            }
            notificationTaskRequest["users"]["lastLogin"]["gt"] = userStartLastLogin
        }
        if (userEndLastLogin) {
            if (!notificationTaskRequest["users"].hasOwnProperty("lastLogin")) {
                notificationTaskRequest["users"]["lastLogin"] = {}
            }
            notificationTaskRequest["users"]["lastLogin"]["lt"] = userEndLastLogin
        }

        //construct schedule payload
        if (scheduleNotification) {
            if (!scheduleType) {
                setAlert("Please Select Schedule Notification Type", "danger")
                window.scrollTo(0, 0)
                return
            }
            notificationTaskRequest["schedule"] = {}
            if (scheduleType == notificationConstants.oneTimeScheduleType) {
                if (!multiScheduleDateTime || multiScheduleDateTime.length == 0) {
                    setAlert("Please Select Scheduling Date & Time", "danger")
                    window.scrollTo(0, 0)
                    return
                }
                notificationTaskRequest["schedule"]["time"] = new Date(multiScheduleDateTime[0]).getTime()
            }
            else if (scheduleType == notificationConstants.repeatScheduleType) {
                if (!multiScheduleTime || multiScheduleTime.length == 0) {
                    setAlert("Please Select Scheduling Time", "danger")
                    window.scrollTo(0, 0)
                    return
                }
                if (!scheduleDayOfWeek || scheduleDayOfWeek.length == 0) {
                    setAlert("Please Select Scheduling Day of Week", "danger")
                    window.scrollTo(0, 0)
                    return
                }
                notificationTaskRequest["schedule"]["time"] = new Date(multiScheduleTime[0]).getTime()
                notificationTaskRequest["schedule"]["repeatType"] = scheduleDayOfWeek
            }
        }


        //construct expiry payload
        // expiry date is a mandatory parameter
        if (!expiryDate) {
            setAlert("Inavlid Expiry Type. Please select expiry time", "danger")
            window.scrollTo(0, 0)
            return
        }
        notificationTaskRequest["expiry"] = Math.round(expiryDate / 1000)


        // check if user file is uploaded
        if (uploadedUserIdFileInput && uploadedUserIdFileInput != "") {
            if (!uploadedUserIdFileInput?.type?.includes("csv")) {
                setAlert("Please upload a csv file.", "danger")
                window.scrollTo(0, 0)
                return
            }
            parseUploadedUserIdFile()
                .then((arrUserIdsData) => {
                    notificationTaskRequest["users"]["userIds"] = arrUserIdsData
                    setNotificationTaskData({});
                    createNotificationTask(notificationTaskRequest)
                    window.scrollTo(0, 0)
                })
                .catch(() => {
                    setAlert("Failed to Parse User IDs Uploaded List", "danger")
                })
        }
        else {
            setNotificationTaskData({});
            createNotificationTask(notificationTaskRequest)
            window.scrollTo(0, 0)
        }
    }

    useEffect(() => {
        return () => {
            clearTimeout(fetchNotificationTimeout);
        }
    }, [])

    useEffect(() => {
        const expectedUserCount = lastNotificationDetails?.status?.taskProcessStatus?.userCount + lastNotificationDetails?.status?.taskProcessStatus?.userFailedCount
        const userSentCount = lastNotificationDetails?.status?.notificationProcessStatus?.userSentCount;
        const failedUserCount = lastNotificationDetails?.status?.notificationProcessStatus?.failedUserSentCount + lastNotificationDetails?.status?.notificationProcessStatus?.tokenNotFoundUserCount + lastNotificationDetails?.status?.taskProcessStatus?.userFailedCount
        if (lastNotificationDetails?.taskId) {
            clearTimeout(fetchNotificationTimeout);
            if (!(lastNotificationDetails?.status?.taskStatus === 'processed' && ((expectedUserCount === userSentCount) || (expectedUserCount === failedUserCount)))) {
                setFetchNotificationTimeout(setTimeout(() => {
                    getNotificationTask(lastNotificationDetails?.taskId, "updateSentNotification")
                }, notificationDetailsRefreshRate))
            }
        }
        setNotificationTaskData(JSON.stringify(lastNotificationDetails) !== "{}" ? {
            expectedUserCount: <div title="The expected number of users that will receive the  notification.">
                {
                    expectedUserCount
                }
            </div>,
            fullTaskId: lastNotificationDetails?.taskId,
            taskId: <div>
                <div title={lastNotificationDetails?.taskId}>
                    {
                        "..." + lastNotificationDetails?.taskId?.substring(lastNotificationDetails?.taskId?.length - 6, lastNotificationDetails?.taskId?.length)
                    }
                </div>
            </div>,
            userCount: <div title={"The number of users that received the notification."}>
                {
                    userSentCount
                }
            </div>,
            taskStatus: lastNotificationDetails?.status?.taskStatus,
            failureCount: <div title={"The number of users the notification failed to reach."}>
                {
                    failedUserCount
                }
            </div>,
            notificationType: notificationChannelTypes[lastNotificationDetails?.channel?.type + "Notification"]?.label,
            severity: lastNotificationDetails?.severity,
            createdAt: format(new Date(lastNotificationDetails?.creationDate * 1000), "dd MMM yyyy 'AT' hh:mm a"),
            expiryDate: format(new Date(lastNotificationDetails?.expiry * 1000), "dd MMM yyyy 'AT' hh:mm a"),
            payload: { ...lastNotificationDetails },
            msg: lastNotificationDetails?.message?.payload,
            action: (data) => {
                return <button
                    className="btn btn-style-3  sm font-400"
                    onClick={e => {
                        setDialog(true);
                        setDialogTab(0);
                        setDialogContents({ content: "notificationDetails", title: "Notification Details", data })
                    }}
                >
                    View
                </button>
            }
        } : {})
    }, [lastNotificationDetails])

    return (
        <Fragment>
            <Alert />

            {
                notificationTaskData && JSON.stringify(notificationTaskData) !== "{}"
                &&
                <>
                    <div style={{ marginTop: "8px" }} className="page-title">
                        <h3>Last Sent Notification details</h3>
                    </div>
                    <table
                        width="100%"
                        align="center"
                        className="table style-4 mb-20 notificationDataTable"
                    >
                        <thead
                            style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                        >
                            <tr>
                                {Object.keys(tableHeaders).map((key) => (
                                    <th style={tableHeaders[key]?.hasOwnProperty("style") ? tableHeaders[key]?.style : {}}>{tableHeaders[key]?.label}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ background: "white" }}>

                                {
                                    Object.keys(tableHeaders).map((key) => {
                                        return (
                                            <td
                                                style={tableHeaders[key]?.hasOwnProperty("style") ? tableHeaders[key]?.style : {}}
                                                className={
                                                    String(notificationTaskData[key]).length > 200
                                                        ? "breakWord"
                                                        : ""
                                                }
                                            >
                                                <div style={{ display: 'flex', alignItems: "center" }}>
                                                    {notificationTaskData?.hasOwnProperty(key) ? typeof notificationTaskData[key] === "function" ? notificationTaskData[key](notificationTaskData) : notificationTaskData[key] : "-"}

                                                    {key === "taskId" &&
                                                        <div onClick={e => {
                                                            setCopied(true);
                                                            setTimeout(() => {
                                                                setCopied(false)
                                                            }, 3000)
                                                            navigator?.clipboard?.writeText(notificationTaskData?.fullTaskId)
                                                        }} title="Copy Task ID" style={{ marginLeft: "8px" }}>
                                                            {
                                                                copied ?
                                                                    <CheckCircleOutlineIcon />
                                                                    :
                                                                    <FileCopyIcon />
                                                            }
                                                        </div>
                                                    }
                                                </div>

                                            </td>
                                        );
                                    })}
                            </tr>
                        </tbody>
                    </table>

                </>
            }
            <div className="content-block bottom-shadow">

                <div className="py-15 ">
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Channel: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" className={classes.selectValues}>
                                <InputLabel id="select-outlined-label-category-name" className="title">Select Channel</InputLabel>
                                <Select
                                    labelId="select-outlined-label-category-name"
                                    id="select-outlined-ctageory-name-id"
                                    value={channelType}
                                    onChange={(e) => {
                                        setChannelType(e.target.value)
                                    }}
                                    label="Select Channel"
                                    className={classes.selectInput}
                                    MenuProps={menuProps}
                                >
                                    {Object?.keys(notificationChannelTypes)?.map(c => {
                                        return <MenuItem key={notificationChannelTypes[c]?.value} value={notificationChannelTypes[c]?.value}>{notificationChannelTypes[c]?.label}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Expiry: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <DatePicker
                                selected={expiryDate}
                                minDate={new Date()}
                                onChange={(date) => {
                                    setExpiryDate(date?.getTime())
                                }}
                                placeholderText="Expiry Date"
                                dateFormat='MM/dd/yyyy'
                                className="add-input calendar input_phone"
                            />
                        </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: "20px" }}>
                        <Grid item xs={2} style={{ marginLeft: "10px" }}>
                            <label className="title" style={{ color: 'black', fontSize: '14px' }}>Severity: </label>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl variant="outlined" className={classes.selectValues}>
                                <InputLabel id="select-outlined-label-category-name" className="title">Select Severity</InputLabel>
                                <Select
                                    labelId="select-outlined-label-category-name"
                                    id="select-outlined-ctageory-name-id"
                                    value={severity}
                                    onChange={(e) => {
                                        setSeverity(e.target.value)
                                    }}
                                    label="Select Severity"
                                    className={classes.selectInput}
                                    MenuProps={menuProps}
                                >
                                    <MenuItem key={notificationConstants.notifySeverityInfo} value={notificationConstants.notifySeverityInfo}>Info</MenuItem>
                                    <MenuItem key={notificationConstants.notifySeverityAlert} value={notificationConstants.notifySeverityAlert}>Alert</MenuItem>
                                    <MenuItem key={notificationConstants.notifySeverityRecommendation} value={notificationConstants.notifySeverityRecommendation}>Recommendation</MenuItem>
                                    <MenuItem key={notificationConstants.notifySeverityPromotion} value={notificationConstants.notifySeverityPromotion}>Promotion</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </div>
            {
                channelType !== "" && (channelType == notificationChannelTypes?.emailNotification?.value ?
                    <EmailNotification
                        handleMessageData={(name, value) => { setEmailNotificationData({ ...emailNotificationData, [name]: value }) }}
                        messageData={emailNotificationData}
                    ></EmailNotification>
                    :
                    channelType == notificationChannelTypes?.pushNotification?.value ?
                        <PushNotification
                            allowedDeviceCategories={allowedDeviceCategories}
                            allowedCompanyBrand={allowedCompanyBrand}
                            setAlert={setAlert}
                            pnMessage={pnMessage}
                            handleMessageData={onChangePushNotificationMessageData}
                            pnPlatforms={pnPlatforms}
                            handlePlatformChange={onChangePushNotificationPlaformType}
                        ></PushNotification>
                        :
                        <SMSNotification messageData={smsNotificationData} handleMessageData={setSmsNotificationData} >
                        </SMSNotification>)
            }
            {/* {
                channelType == notificationConstants.emailNotification && <EmailNotification
                    allowedDeviceCategories={allowedDeviceCategories}
                    allowedCompanyBrand={allowedCompanyBrand}
                    setAlert={setAlert}
                    pnMessage={pnMessage}
                    handleMessageData={onChangePushNotificationMessageData}
                    pnPlatforms={pnPlatforms}
                    handlePlatformChange={onChangePushNotificationPlaformType}
                ></EmailNotification>
            } */}
            <UserSelection
                allowedDeviceCategories={allowedDeviceCategories}
                allowedCompanyBrand={allowedCompanyBrand}
                allowedCountries={allowedCountries}
                setAlert={setAlert}
                uploadedUserIdFileInput={uploadedUserIdFileInput}
                handleUserIdFileInput={onChangeUserIdListFileInput}
                userIdCSVFileInputRef={userIdCSVFileInputRef}
                country={country}
                handleUserCountries={onChangeUserCountry}
                deviceOwnership={deviceOwnership}
                handleUserDeviceOwnership={onChangeUserDeviceOwnership}
                userStartLastLogin={userStartLastLogin}
                userEndLastLogin={userEndLastLogin}
                handleUserStartLastLogin={onChangeUserStartLastLogin}
                handleUserEndLastLogin={onChangeUserEndLastLogin}
                userLevel={userLevel}
            ></UserSelection>
            <ScheduleNotification
                setAlert={setAlert}
                scheduleNotification={scheduleNotification}
                handleScheduleNotification={onChangeScheduleNotification}
                scheduleType={scheduleType}
                handleScheduleTypeChange={onChangeScheduleType}
                multiScheduleDateTime={multiScheduleDateTime}
                handleMultiScheduleDateTime={onChangeMultiScheduleDateTime}
                scheduleDayOfWeek={scheduleDayOfWeek}
                handleScheduleDayOfWeekChange={onChangeScheduleDayOfWeek}
                multiScheduleTime={multiScheduleTime}
                handleMultiScheduleTimeChange={onChangeMultiScheduleTime}
                handleDeleteMultiScheduleTime={onDeleteMultiScheduleTime}
            ></ScheduleNotification>
            <div className="row">
                <button
                    className="btn btn-style-2 orange-bg"
                    onClick={handleCreateNotificationTask}
                    disabled={sendNotificationInProgress ? true : false}
                    style={{ marginLeft: "10px" }}
                >
                    {
                        sendNotificationInProgress ? <Fragment>
                            <CircularProgress size="2em" style={{ color: "#AFB42B", marginTop: "5px" }} />
                        </Fragment>
                            : "Create Notification Task"
                    }
                </button>
                <button
                    className="btn btn-style-2 orange-bg" onClick={handleCreateNotificationTaskReset}
                    style={{ marginLeft: "20px" }}
                    disabled={sendNotificationInProgress ? true : false}
                >
                    Reset
                </button>
            </div>
        </Fragment>
    );
}

export default SendNotification