import { QRCodeSVG } from "qrcode.react";
import React, { Fragment, useState } from "react";
import customConfig from "../../config";
import PropTypes from "prop-types";
import { connect } from "react-redux";


const QRdisplay = ({qrCodeUrl}) => {
  const [showInstructions, setShowInstructions] = useState(false);

  const handleInstructionsClick = (e) => {
    e.preventDefault();
    setShowInstructions(true);
  };

  const closeInstructions = () => {
    setShowInstructions(false);
  };

  return (
    <Fragment>
      {showInstructions && (
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={closeInstructions}>&times;</span>
                  <h2>Set up 2-Step Authentication</h2>
                  <ol>
                  {customConfig.mfaSetupInstuctions.map((instruction, index) => (
                   <li key={index}>{instruction}</li> ))
                  }  
                  </ol>
                </div>
              </div>
            )}
      <h2 className="center-align" style={{ padding: '20px 0' }}>
        Scan this QR code to set up 2-Step Authentication
      </h2>
      <div className="row mb-0">
        <div className="input-field p-0 col s12 center-align">
          {qrCodeUrl ? (
            <QRCodeSVG value={qrCodeUrl} size={256} />
          ) : (
            <p>Loading QR Code...</p>
          )}
          <div style={{ marginTop: '10px' }}>
            <a href="#" onClick={handleInstructionsClick} style={{ color: 'blue' }}>Need help?</a>
          </div>
        </div>
      </div>

    </Fragment>
  )
}

QRdisplay.propTypes = {
  qrCodeUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  qrCodeUrl: state.auth.qrCodeUrl,
});

export default connect(mapStateToProps)(QRdisplay);