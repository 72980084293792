import { useState } from "react";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import Alert from "../../../layout/Alert";


const DACCount = ({ dacData, getDACCount, ...props }) => {

    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);
    const [showDACData, setShowDACData] = useState(false);

    const handleStartDateChange = (date) => {
        setStartDateFilter(date)
        setEndDateFilter(null);
        setShowDACData(false)
    }
    const handleEndDateChange = (date) => {
        setEndDateFilter(date);
        setShowDACData(false)
    }
    const filter = () => {
        setShowDACData(false);
        const startDate = format(new Date(Number(startDateFilter)), "dd-MM-yyyy")
        const endDate = format(new Date(Number(endDateFilter)), "dd-MM-yyyy")
        getDACCount({
            startTs: startDate, endTs: endDate,
        }, () => {
            setShowDACData(true)
        })
    }

    return (<div>
        <div className="content-block bottom-shadow">
            <div className="py-15 ">
                <div className="row mb-0"
                    style={{ display: 'flex', alignItems: "center" }}
                >
                    <div>
                        <div className="col" style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ marginRight: "8px" }}>
                                From:
                            </div>
                            <DatePicker
                                selected={startDateFilter}
                                maxDate={new Date()}
                                onChange={handleStartDateChange}
                                placeholderText="Start Date"
                                dateFormat='MM/dd/yyyy'
                                className="add-input calendar input_phone"
                            />
                        </div>
                        <div className="col" style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ marginRight: "8px" }}>
                                To:
                            </div>
                            <DatePicker
                                selected={endDateFilter}
                                minDate={startDateFilter}
                                maxDate={new Date()}
                                onChange={handleEndDateChange}
                                placeholderText="End Date"
                                dateFormat='MM/dd/yyyy'
                                className="add-input calendar input_phone"
                            />
                        </div>
                    </div>
                    <div className="col" style={{ padding: "0px !important" }}>
                        <button
                            style={{ margin: "16px 0px", height: "40px" }}
                            className="btn btn-style-3  sm mr-10 font-400"
                            onClick={e => filter()}
                            disabled={startDateFilter === null || endDateFilter === null}
                        >
                            Filter
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <Alert/>
        {showDACData && <div className="content-block bottom-shadow">
            <div style={{ margin: "8px", marginLeft: "16px", display: "flex" }} className="page-title">
                <h3>{`DAC Count : ${dacData?.hasOwnProperty("count") ? dacData?.count : "-"}`}</h3>
            </div>
        </div>}
    </div>
    )
}

export default DACCount