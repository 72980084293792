import axios from "axios";
import customConfig from "../config";
import env from "../env";
import iduConfig from "../reducers/iduConfig";

const setTenantId = (tenantId) => {
  if (tenantId) {
    axios.defaults.headers.common["X-Tenant-ID"] = tenantId;
  } else {
    delete axios.defaults.headers.common["X-Tenant-ID"];
  }

};

export default setTenantId;
