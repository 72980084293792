export const deviceDiagnosticsPageSize = 100;
export const startDateLimit = 89;
export const ACDiagnosticHeaders = {
    ACv_o: { header: "Voltage (V)", desc: "AC Voltage available at Input Power Supply" },
    DCv_o: { header: "DC Voltage (V)", desc: "DC Voltage of Power rail" },
    Falt_a: { header: "Fault types", desc: "Fault Types" },
    Flvl_i: { header: "IDU Fan Speed (0..6)", desc: "IDU Fan Speed" },
    Tr_i: { header: 'Air Temp (°C)', desc: 'Indoor Room Air Temperature' },
    Te_i: { header: 'Pipe Temp (°C)', desc: "Evaporator Pipe Temperature" },
    Tu_i: { header: 'Set Temp (°C)', desc: "Set Temperature by User" },
    Frpm_i: { header: 'Motor Speed (RPM)', desc: "Speed of IDU Fan Motor" },
    PM25_i: { header: 'Indoor PM2.5 (ug/m3)', desc: "Indoor PM2.5 Sensor Reading" },
    Fsta_i: { header: 'Filter Status', desc: "Filter Status" },
    Tsu_o: { header: 'Suction Pipe Temp (°C)', desc: "Suction Pipe Temperature" },
    Ta_o: { header: 'Outdoor Air Temp (°C)', desc: "Outdoor Air Temperature" },
    Tc_o: { header: 'Condenser Pipe Temp (°C)', desc: "Condenser Pipe Temperature" },
    Frpm_o: { header: 'Fan Speed (RPM)', desc: "Outdoor Fan Speed" },
    Pcur_o: { header: 'DC Current (A)', desc: "DC Current of U,V,W Phase to compressor" },
    ACc_o: { header: 'AC Current (A)', desc: "Total Input AC Current Taken by Outdoor Unit" },
    Td_o: { header: 'Top Pipe Temp (°C)', desc: "Compressor Top Pipe Temperature" },
    Tipm_o: { header: 'Temp of IPM (°C)', desc: "Temperature of IPM (Driving Circuit of Compressor)" },
    Trpm_c: { header: 'Algorithm RPM of Comp.', desc: "The target RPM of Compressor decided by Algorithm" },
    Srpm_c: { header: 'Set RPM of Comp.', desc: "The RPM of Compressor set as per current situation" },
    Rrpm_c: { header: 'Realtime RPM of Comp.', desc: "Realtime RPM of Compressor on which it is running" },
    Lrpm_a: { header: 'Limit on RPM of Comp.', desc: "Reason due to Which RPM of Comp. has been Limited" },
    Prot_a: { header: 'Temporary Protections', desc: 'Temporary Protections due to which the Unit has stopped working' },
    POn_t: { header: 'Total P On Time (sec)', desc: "Total Power On Time" },
    V: { header: "Version", desc: "Version" },
    in_s: { header: "Interval time (sec)", desc: 'Interval time' },
    ps: { header: "Power State", desc: 'Power State' },

}
export const commonHeaders = {
    // serialNumber: "S.No",
    ts: { header: "Timestamp", desc: "" },
    st: { header: "Start Time", desc: "Operation start time" }
};