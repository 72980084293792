import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from "@material-ui/core";
import { useEffect, useState } from 'react';
import M from 'materialize-css/dist/js/materialize.min';
import { getDACCount, getDeviceDACLogsData } from '../../../action/devicelogs';
import { connect } from 'react-redux';
import DeviceDACLogs from './DeviceDACLogs/DeviceDACLogs';
import DACCount from './DACCount/DACCount';

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: 'gray',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});


const DACStatistics = ({
    wrapperClass = "right-content-wraper",
    dacData,
    getDeviceDACLogsData = () => { },
    getDACCount = () => { }
}) => {

    const classes = useStyles()
    const [activeTab, setActiveTab] = useState(0)

    useEffect(() => {
        M.AutoInit();
    }, [dacData]);

    return (
        <div className={wrapperClass}>
            <div className="row">
                <Tabs
                    value={activeTab}
                    onChange={(e, newValue) => {
                        setActiveTab(newValue)
                    }}
                    TabIndicatorProps={{
                        style: {
                            backgroundColor: "#bdbdbd"
                        }
                    }}
                    style={{ marginTop: '20px' }}
                >
                    <Tab label="DAC Statistics" className={activeTab == 0 ? classes.activeTab : classes.nonActiveTab} />
                    <Tab label="Find Statistics" className={activeTab == 1 ? classes.activeTab : classes.nonActiveTab} />
                </Tabs>
                {
                    <div className="container">
                        <div> {(() => {
                            switch (activeTab) {
                                case 0:
                                    return <DACCount
                                        getDACCount={getDACCount}
                                        dacData={dacData} />
                                case 1:
                                    return <DeviceDACLogs
                                        showSearch={true}
                                        showCount={true}
                                        deviceDetails={{}}
                                        dacData={dacData}
                                        getDeviceDACLogsData={getDeviceDACLogsData}
                                    />
                            }
                        })()}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    dacData: state.report?.deviceLogs,
});

const mapDispatchToProps = (dispatch) => ({
    getDACCount: (params, cb) => dispatch(getDACCount(params, cb)),
    getDeviceDACLogsData: (params, cb) => dispatch(getDeviceDACLogsData(params, cb)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DACStatistics);
