import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getDevice, getDeviceUsagebyId, getFirmwareHistory, getOnboardedHistory, getReportbyId, getDiagnosticsData } from "../../action/report";
import DeviceDetails from "../device/DeviceDetails";
import customConfig from "../../config";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DeviceUpdateLogs from "./../device/DeviceUpdateLogs";
import { makeStyles } from '@material-ui/core/styles';
import DeviceDiagnostics from "../device/DeviceDiagnostics/DeviceDiagnostics";
import DeviceConnDisconnLogs from "../device/DeviceConnDisconn/DeviceConnDisconnLogs";
import { downloadDeviceDiagnosticReport, downloadDeviceStatusReport, getDeviceConnDisconnLogsData, getDeviceDACLogsData, getDeviceDiagnosticsData, getDeviceStatusData } from "../../action/devicelogs";
import DeviceStatus from "../device/DeviceStatus/DeviceStatus";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { getFormatedJSON } from "../../utils/general";
import { getDeviceLogPermission } from "../device/DeviceLogsConstants";
import DeviceDACLogs from "../device/DACStatistics/DeviceDACLogs/DeviceDACLogs";

const useStyles = makeStyles({
  activeTab: {
    border: '1px solid #bdbdbd',
    backgroundColor: '#e0e0e0',
    textTransform: 'capitalize'
  },
  nonActiveTab: {
    border: 'none',
    backgroundColor: 'none',
    textTransform: 'capitalize'
  },
  TabContainer: {
    "& .MuiTabs-fixed": {
      overflow: 'auto !important',
      width: '100%'
    },
  },
});

const ReportDevices = ({
  user: { user, umV1AuthToken },
  tenant: { tenantInfo },
  userRoleInfo,
  getDeviceDiagnosticsData,
  downloadDeviceDiagnosticReport,
  getDeviceConnDisconnLogsData,
  getDeviceDACLogsData,
  downloadDeviceStatusReport,
  getDeviceStatusData,
  getDevice,
  report: { device, deviceUsage, latestFirmwareData,
    firmwareData, onBoardedHistoryData, diagnosticData, deviceLogs },
  selectedData, getDeviceUsagebyId, getFirmwareHistory, getOnboardedHistory, getDiagnosticsData, getReportbyId, homeId }) => {

  const tabs = [{ label: 'Device Info', permission: "deviceInfo" }, { label: "DAC Logs", permission: "dacLogs" }, { label: "Device Logs", permission: 'deviceLogs' }, { label: "Connection Status Logs", permission: "connectionStatusLogs" }, { label: "Diagnostic Logs", permission: "diagnosticLogs" }, { label: "Status Logs", permission: "statusLogs" }]

  const [Id, setId] = useState(null);
  const [isEdit, setIsEdit] = useState(false)

  const classes = useStyles()
  const [activeTab, setActiveTab] = React.useState(0);
  const [dialog, setDialog] = useState(false);
  const [dialogContents, setDialogContents] = useState({ title: '', data: {} });
  const [availableTabs, setAvailableTabs] = React.useState([]);
  const [selectedTab, setSelectedTab] = React.useState(availableTabs[0]);

  useEffect(() => {
    setId(null);
  }, [selectedData]);

  useEffect(() => {
    const visibleTabs = tabs?.filter(t => {
      return getDeviceLogPermission(userRoleInfo?.roles, t?.permission)
    })?.map(t => t?.label)
    setAvailableTabs(visibleTabs)
    setSelectedTab(visibleTabs[0])
  }, [userRoleInfo?.roles])

  const viewAcDetails = tab => {
    if (isEdit) return null
    getDevice(tab.deviceId, customConfig.deviceSearchByDeviceId, userRoleInfo, umV1AuthToken, tenantInfo.tenantId);
    setId(tab.deviceId);
    getFirmwareHistory(tab.deviceId);
    getDeviceUsagebyId(tab.deviceId);
    getOnboardedHistory(tab.deviceId);
    // getDiagnosticsData(tab.deviceId);
  };

  const handleNotifyOffboard = () => {
    getReportbyId(homeId);
  }

  return (
    <div className="p-15">
      <div id="tab3" className="col s12">

        <div className="hd-style-2">
          <h5 className="mb-5">Devices in {selectedData.spaceName} {selectedData.devices.length > 0 ? "(" + selectedData.devices.length + ")" : ""}</h5>
          <div className="font-14 gray-txt mb-15">
            Select an option to view device detail reports{" "}
          </div>
        </div>



        <div className="row">
          <div className="col s12">
            {selectedData.devices.length === 0 ? (
              <div className="noDeviceFound radius-10 light-gray">
                <div className="text-box"><i className="no-device-icon"></i><label>No device found </label></div>
              </div>
            ) : (
              <ul className="tabs detail-tab reset-thumb scroll-set">
                {selectedData &&
                  selectedData.devices.map((mydevice, i) => (
                    <li key={i} className="tab">
                      <Link
                        to={"#"}
                        onClick={() => viewAcDetails(mydevice)}
                        className={Id === mydevice.deviceId ? "active" : ""}
                      >
                        {" "}
                        {/* <i className="tabs-icon smart-room-ac"></i> */}
                        <i className={`tabs-icon ${mydevice.categoy === 'AC' ? "smart-room-ac" : mydevice.categoy === 'Panasonic Fan' ? "smart-fan" : mydevice.categoy === 'Panasonic Television' ? "television" : mydevice.categoy === 'ODMFRONTLOADWM' ? "washing-maching" : mydevice.categoy === 'REFRIGERATOR' ? "refrigerator" : mydevice.categoy === 'Panasonic Smart Plug' ? "smart-plug" : mydevice.categoy === 'Panasonic Switch Device' ? "switch-device" : mydevice.categoy === 'Panasonic door-phone' ? "door-phone" : "default-device"}`}></i>
                        <div className="title">{mydevice.deviceName} </div>

                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>

        {Id && selectedData && selectedData.devices.length > 0 ? (
          <Fragment>
            <div className={classes?.TabContainer}>
              <Tabs
                value={activeTab}
                onChange={(e, newValue) => {
                  setActiveTab(newValue)
                  setSelectedTab(availableTabs[newValue])
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#bdbdbd",
                    float: 'right'
                  }
                }}
                style={{ marginTop: '20px' }}
              >
                {availableTabs?.map((t) => <Tab label={t} className={selectedTab === t ? classes.activeTab : classes.nonActiveTab} style={{ border: '1px solid black' }} />)}
              </Tabs>
            </div>


            {(() => {
              switch (selectedTab) {
                case "Device Info":
                  return <div>
                    <div className="row mb-0">
                      <div className="s12 p-0 col">
                        <div className="content-block position-relative">
                          <div className="p-15">
                            <DeviceDetails showSeparator={true} device={device} deviceUsage={deviceUsage} latestFirmwareData={latestFirmwareData} firmwareData={firmwareData} onBoardedHistoryData={onBoardedHistoryData} diagnosticData={diagnosticData} notifyOffboard={handleNotifyOffboard} homeId={homeId} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                case "DAC Logs":
                  return <DeviceDACLogs
                    getDeviceDACLogsData={getDeviceDACLogsData}
                    dacData={deviceLogs}
                    deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
                  />
                case "Device Logs":
                  return <DeviceUpdateLogs
                    deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
                  />
                case "Connection Status Logs":
                  return <DeviceConnDisconnLogs
                    getDeviceConnDisconnLogsData={getDeviceConnDisconnLogsData}
                    connectionStatusLogs={deviceLogs}
                    deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
                  />
                case "Diagnostic Logs":
                  return <DeviceDiagnostics
                    diagnosticData={deviceLogs}
                    setDialog={setDialog}
                    setDialogContents={setDialogContents}
                    deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
                    getDeviceDiagnosticsData={getDeviceDiagnosticsData}
                    downloadDeviceDiagnosticReport={downloadDeviceDiagnosticReport}
                  />
                case "Status Logs":
                  return <DeviceStatus
                    setDialog={setDialog}
                    setDialogContents={setDialogContents}
                    statusData={deviceLogs}
                    deviceDetails={device ? device.length > 1 ? device[1] : device[0] : {} ?? {}}
                    getDeviceStatusData={getDeviceStatusData}
                    downloadDeviceStatusReport={downloadDeviceStatusReport}
                  />
              }
            })()}
          </Fragment>
        ) : null}

      </div>
      <Dialog
        className={classes?.Dialog}
        open={dialog}
      >
        <DialogTitle>
          {dialogContents?.title}
        </DialogTitle>
        <DialogContent>
          <div className="dialogContents">
            {getFormatedJSON(dialogContents?.data)}
          </div>
          <div className="buttonContainer grey-theme">
            <button
              style={{ marginLeft: "8px", }}
              className="btn btn-style-3 sm font-400"
              onClick={(e) => { setDialog(false) }}
            >
              Close
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};
const mapStateToProps = state => ({
  report: state.report,
  userRoleInfo: {
    level: state.auth.userLevel,
    roles: state.auth.userRoles,
  },
  user: state.user,
  tenant: state.tenant,
});

const mapDispatchToProps = (dispatch) => ({
  getDevice: (arg1, arg2, arg3, arg4, arg5) => dispatch(getDevice(arg1, arg2, arg3, arg4, arg5)),
  getDeviceUsagebyId: (arg1) => dispatch(getDeviceUsagebyId(arg1)),
  getFirmwareHistory: (arg1) => dispatch(getFirmwareHistory(arg1)),
  getOnboardedHistory: (arg1) => dispatch(getOnboardedHistory(arg1)),
  getDeviceDiagnosticsData: (params, cb) => { dispatch(getDeviceDiagnosticsData(params, cb)) },
  getDeviceDACLogsData: (params, cb) => { dispatch(getDeviceDACLogsData(params, cb)) },
  downloadDeviceDiagnosticReport: (params, cb) => { dispatch(downloadDeviceDiagnosticReport(params, cb)) },
  getDeviceConnDisconnLogsData: (deviceId, cb) => { dispatch(getDeviceConnDisconnLogsData(deviceId, cb)) },
  getDeviceStatusData: (params, cb) => { dispatch(getDeviceStatusData(params, cb)) },
  downloadDeviceStatusReport: (params, cb) => { dispatch(downloadDeviceStatusReport(params, cb)) },
  getReportbyId: (homeId) => { dispatch(getReportbyId(homeId)) }
})


export default connect(mapStateToProps, mapDispatchToProps)(ReportDevices);
