import React, { Fragment, useState } from 'react';
import PropTypes from "prop-types";
import {
  Typography,
  Button,
  Link as MuiLink,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Alert from "../../layout/Alert";
import { connect } from 'react-redux';
import { addMfaDevice, disableMFA } from '../../../action/auth';


const Settings = ({
  wrapperClass = "right-content-wraper",
  addMfaDevice,
}) => {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const handleOpenConfirmationDialog = () => {
    setOpenConfirmationDialog(true);
  };

  const handleCloseConfirmationDialog = () => {
    setOpenConfirmationDialog(false);
  };

  const handleOpenChangeDeviceDialog = () => {
    addMfaDevice();
    handleCloseConfirmationDialog(false);
  };

  return (
    <Fragment>
      <div className={wrapperClass}>
        <div className="container">
          {/* Will release this in next release */}
          {/* <div className="row">
            <div className="s12 col">
              <div className="content-block bottom-shadow mb-0">
                <div className="p-15">
                  <div className="row reset-device-block mb-0">
                    <div className="s12 col">
                      <div className="page-title">
                        <Typography variant="h6">
                          Change Password
                        </Typography>
                        <Typography variant="body2">
                          Need to update your password? Click <MuiLink to="">here</MuiLink>
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <div className="row">
            <div className="s12 col">
              <div className="content-block bottom-shadow mb-0">
                <div className="p-15">
                  <div className="row reset-device-block mb-0">
                    <div className="s6 col">
                      <Typography variant="h6">
                        Two Step Authentication
                      </Typography>
                    </div>
                    <div className="input-field col s12" style={{ textAlign: 'right' }}>
                      <button
                        className="btn btn-style-3  mr-10 font-400"
                        onClick={handleOpenConfirmationDialog}
                      >
                        Add Device
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mid-seprator"></div>
          <Alert />
        </div>
      </div>
      <Dialog
        open={openConfirmationDialog}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add Two-Step Authentication Device?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to add your Two-Step Authentication device? This cannot be reverted back.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmationDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleOpenChangeDeviceDialog} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

Settings.propTypes = {
  addMfaDevice: PropTypes.func.isRequired,
}

const mapDispatchToProps = (dispatch) => ({
  addMfaDevice: () => dispatch(addMfaDevice()),
});

export default connect(null, mapDispatchToProps)(Settings);
