import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { mfaValidate, validateUserRole } from "../../action/auth"; 
import Alert from "../layout/Alert";
import customConfig from "../../config"; 

const MfaValidate = ({ mfaValidate, tenantData, mfaToken, validateUserRole }) => {
    const [mfaCode, setMfaCode] = useState("");
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [showInstructions, setShowInstructions] = useState(false); 

    const handleInstructionsClick = (e) => {
        e.preventDefault();
        setShowInstructions(true);
    };

    const closeInstructions = () => {
        setShowInstructions(false);
    };

    const handleChange = (e) => {
      const value = e.target.value;
      if (/^\d{0,6}$/.test(value)) {
        setMfaCode(value);
      }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!mfaCode) {
            setError("MFA Code is required");
            return;
        }
    mfaValidate(mfaCode, mfaToken)
    .then((res) => {
        if (res.data.accessToken) {
          validateUserRole(res)
        }
      })
      .catch((err) => console.error("Login Error: ", err));
    };

    return (
        <Fragment>
            <div className="login-wrapper">
                <div className="login-box">
                    <div className="form-box">
                        <div className="row mb-0">
                            <Alert />
                            <div className="col s12 center-align">
                                <img
                                    src={tenantData.tenantLogoUrl}
                                    alt="Logo"
                                    className="logo-outer"
                                />
                            </div>
                        </div>
                        <h2 className="center-align" style={{ padding: ' 20px' }}>
                          Enter 2-Step Authentication code
                        </h2>
                        <form className="form" onSubmit={handleSubmit} noValidate>
                            <div className="row">
                                <div className="input-field p-0 col s12">
                                    <input
                                        type="number"
                                        id="mfaCode"
                                        name="mfaCode"
                                        value={mfaCode}
                                        onChange={handleChange}
                                        required
                                        placeholder="Code"
                                        className={`add-input xl ${error && "is-danger"}`}
                                    />
                                    {error && (
                                        <p className="help is-danger error-message">
                                            {error}
                                        </p>
                                    )}
                                </div>
                            </div>
                            {showInstructions && (
                              <div className="modal">
                                <div className="modal-content">
                                  <span className="close" onClick={closeInstructions}>&times;</span>
                                  <h2>How to Enter 2-Step Authentication Codes</h2>
                                  <ol>
                                    {customConfig.mfaValidationInstructions.map((instruction, index) => (
                                      <li key={index}>{instruction}</li>
                                    ))}
                                  </ol>
                                </div>
                              </div>
                            )}
                            <div style={{ textAlign: 'center', padding: '10px'}}>
                              <a href="#" onClick={handleInstructionsClick} style={{ color: 'blue' }} >Need help entering codes?</a>
                            </div>
                            <div className="row mb-0">
                                <div className="input-field p-0 col s12">
                                    <button
                                        className="btn add-btn xl col s12  mTop20"
                                        type="submit"
                                        name="action"
                                    >
                                        Validate
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

MfaValidate.propTypes = {
    mfaToken: PropTypes.string.isRequired,
    mfaValidate: PropTypes.func.isRequired,
    tenantData: PropTypes.object.isRequired,
    validateUserRole: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    mfaToken: state.auth.mfaToken,
  });

const mapDispatchToProps = (dispatch) => ({
    mfaValidate: (otp, mfaToken) => dispatch(mfaValidate(otp, mfaToken)),
    validateUserRole: (res) => dispatch(validateUserRole(res)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MfaValidate);
