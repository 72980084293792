import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, MenuItem, IconButton, ListItemIcon, Switch, ListItemText } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PersonIcon from '@material-ui/icons/Person';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import customConfig from '../../config';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

const UserProfileMenu = ({ logout }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewProfile = () => {
    handleMenuClose();
  };

  const handleSupport = () => {
    handleMenuClose();
    window.open(customConfig.supportURL, '_blank');
  };

  const handleSettings = () => {
    return <Redirect to="/Settings" />;
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  return (
    <div className='profile-icon'>
      <IconButton onClick={handleMenuOpen} style={{ color: 'white', backgroundColor: 'transparent' }} >
        <AccountCircleIcon fontSize="large" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
      >
        {/* <MenuItem onClick={handleViewProfile}>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          View Profile
        </MenuItem> */}

        <MenuItem onClick={handleSupport}>
          <ListItemIcon>
            <HelpIcon fontSize="small" />
          </ListItemIcon>
          Support
        </MenuItem>
        <Link to="/Settings" style={{ textDecoration: 'none', color: 'inherit' }} onClick={handleMenuClose}>
          <MenuItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
        </Link>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
};

export default UserProfileMenu;