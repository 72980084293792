import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { syncMFA } from '../../action/auth'; 
import Alert from "../layout/Alert";
import customConfig from "../../config";

const CodeInput = ({ syncMFA }) => {
  const [code1, setCode1] = useState("");
  const [code2, setCode2] = useState("");
  const [errors, setErrors] = useState({});
  const [showInstructions, setShowInstructions] = useState(false);

  const handleInstructionsClick = (e) => {
    e.preventDefault();
    setShowInstructions(true);
  };

  const closeInstructions = () => {
    setShowInstructions(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let validationErrors = {};
    if (!code1) validationErrors.code1 = "Code 1 is required";
    if (!code2) validationErrors.code2 = "Code 2 is required";
    
    if (code1 && code2 && code1 === code2) {
      validationErrors.code2 = "Code 2 must be different from Code 1";
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    syncMFA({ code1, code2 })
      .then(() => {
        setCode1("");
        setCode2("");
    })
    .catch((err) => {
        setErrors(customConfig.mfaSyncError);
    });
  };

  return (
    <div className="input-code-wrapper">
      <h3 style={{ textAlign: 'center'}}>Enter codes to sync 2-Step Authentication.</h3>
      <Alert />  
      <form onSubmit={handleSubmit} noValidate>
        <div className="row mb-0">
          <div className="input-field p-0 col s12">
            <label htmlFor="code1">Code 1</label>
            <input
              type="number"
              id="code1"
              value={code1}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,6}$/.test(value)) {
                  setCode1(value);
                }
              }}
              required
              placeholder="Enter Code 1"
              className={`add-input xl ${errors.code1 && "is-danger"}`}
              maxLength={6}
            />
            {errors.code1 && (
              <p className="help is-danger error-message">
                {errors.code1}
              </p>
            )}
          </div>
        </div>
        <div className="row mb-0">
          <div className="input-field p-0 col s12">
            <label htmlFor="code2">Code 2</label>
            <input
              type="number"
              id="code2"
              value={code2}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,6}$/.test(value)) {
                  setCode2(value);
                }
              }}
              required
              placeholder="Enter Code 2"
              className={`add-input xl ${errors.code2 && "is-danger"}`}
              maxLength={6}
            />
            {errors.code2 && (
              <p className="help is-danger error-message">
                {errors.code2}
              </p>
            )}
          </div>
        </div>
        {showInstructions && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={closeInstructions}>&times;</span>
              <h2>How to Enter 2-Step Authentication Codes</h2>
              <ol>
                {customConfig.mfaCodeInstructions.map((instruction, index) => (
                  <li key={index}>{instruction}</li>
                ))}
              </ol>
            </div>
          </div>
        )}
        <div style={{ textAlign: 'center', padding: '10px'}}>
          <a href="#" onClick={handleInstructionsClick} style={{ color: 'blue' }} >Need help entering codes?</a>
        </div>
        <div className="row mb-0">
          <div className="input-field p-0 col s12">
            <button
              type="submit"
              className="btn add-btn xl col s12 mTop20"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

CodeInput.propTypes = {
  syncMFA: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  syncMFA: (codes) => dispatch(syncMFA(codes)),
});

export default connect(null, mapDispatchToProps)(CodeInput);
