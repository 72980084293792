import React, { useEffect, useState } from "react";
import './DeviceDiagnostics.css'
import { Fragment } from "react";
import { format } from "date-fns";
import { Box } from "@material-ui/core";
import { commonHeaders } from "./DeviceDiagnosticsConstants";
import { formatTableHeader } from "../../../utils/general";

const DisplayDeviceDiagnosticsData = ({
    deviceCategory,
    deviceDiagnosticsData,
    setDialog,
    setDialogContents
}) => {
    const [headers, setHeaders] = useState({})

    useEffect(() => {
        const dataHeaders = {};
        //building a unique list of headers after going through each record in the dataset
        deviceDiagnosticsData?.map(d => {
            Object?.keys(d?.payload)?.forEach(h => {
                if (!dataHeaders?.hasOwnProperty(h)) {
                    dataHeaders[h] = { header: formatTableHeader(d?.payload[h]?.header !== "" ? d?.payload[h]?.header : h), desc: d?.payload[h]?.desc }
                }
            })
        })
        delete dataHeaders?.ty
        delete dataHeaders?.ts
        delete dataHeaders?.st
        delete dataHeaders?.mo
        setHeaders({
            ...commonHeaders,
            ...dataHeaders,
            ...(() => {
                //not taking any fixed headers for AC
                // if (deviceCategory == 'AC') {
                //     return { ...ACDiagnosticHeaders }
                // }
                return {}
            })(),
            actions: { header: "Actions", desc: "" }
        })
    }, [deviceDiagnosticsData])
    return (
        <Fragment>
            {
                deviceCategory && deviceCategory != "" && deviceDiagnosticsData &&
                <table
                    width="100%"
                    align="center"
                    className="table mb-20 deviceDiagnosticsTable"
                >
                    <thead
                        style={{ position: "sticky", top: 0, zIndex: 50, background: "white" }}
                    >
                        <tr>
                            {Object.keys(headers).map((key, ind) => (
                                <th key={ind} title={headers[key]?.desc} style={{
                                    minWidth: key === 'actions' ? "75px" : "180px", maxWidth: "300px", overflow: "hidden", textOverflow: 'ellipsis',
                                    ...(() => {
                                        if (ind === Object.keys(headers)?.length - 1) {
                                            return { display: "flex", justifyContent: "flex-end" }
                                        }
                                        else {
                                            return {}
                                        }
                                    })()
                                }}
                                >{headers[key]?.header}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {deviceDiagnosticsData.length === 0 ? (
                            <tr>
                                <td colSpan={Object.keys(headers).length}>
                                    No Data found
                                </td>
                            </tr>
                        ) : (
                            Array.isArray(deviceDiagnosticsData) && deviceDiagnosticsData?.map((element, index) => {
                                return (
                                    <tr key={index} id={index}>
                                        {Object.keys(headers).map((key) => {
                                            return (
                                                key == 'serialNumber' ?
                                                    <td className={String(element[key]).length > 60 ? "breakWord" : ""}
                                                    >
                                                        {
                                                            index + 1
                                                        }
                                                    </td>
                                                    :
                                                    key === "actions" ?
                                                        <td style={{ display: "flex", justifyContent: "flex-end" }}>
                                                            <button
                                                                className="btn btn-style-3 sm font-400"
                                                                onClick={e => {
                                                                    setDialogContents({ title: "Diagnostic Details", data: element })
                                                                    setDialog(true);
                                                                }}
                                                            >
                                                                View
                                                            </button>
                                                        </td>
                                                        :
                                                        (key == 'ts' || key === 'st') ?
                                                            <td className={String(element['payload'][key]).length > 60 ? "breakWord" : ""}
                                                            >
                                                                {
                                                                    String(element['payload'][key]) && String(element['payload'][key]).length > 0 ?
                                                                        <Box>
                                                                            {
                                                                                element?.payload?.hasOwnProperty(key) ? format(new Date(Number(element['payload'][key]?.value) * 1000), "dd MMM yyyy, hh:mm a") : "-"
                                                                            }
                                                                        </Box>
                                                                        :
                                                                        ""
                                                                }
                                                            </td>
                                                            :
                                                            <td
                                                                className={
                                                                    String(element["payload"][key]).length > 200
                                                                        ? "breakWord"
                                                                        : ""
                                                                }
                                                            >
                                                                {element["payload"]?.hasOwnProperty(key) ? element["payload"][key]?.value : "-"}
                                                            </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })
                        )}
                    </tbody>
                </table>
            }
        </Fragment>
    );
};
export default DisplayDeviceDiagnosticsData