import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { getCurrentUser } from "../../action/user";
import SearchUser from "./SearchUser";
import M from "materialize-css/dist/js/materialize.min.js";
import Alert from "../layout/Alert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Dashboard = ({
  getCurrentUser,
  user: { user },
  tenant: { tenantInfo },
  showDetail = false,
  allowedCountries,
}) => {
  // useEffect(() => {}, [getCurrentUser]);
  const [text, setText] = useState("");
  const [showText, setShowText] = useState(showDetail);
  const [searchBy, setSearchBy] = useState("mobile");
  const [code, setCode] = useState("91");

  const onSubmit = (e) => {
    e.preventDefault();
    setText("");
    if (code) {
      let searchText = text;
      if (searchBy === "mobile") {
        searchText = `+${code}${text}`;
      }
      getCurrentUser(searchText, searchBy, tenantInfo.tenantId);
      setShowText(true);
    }
  };

  useEffect(() => {
    M.AutoInit();
  }, []);

  const onChange = (e) => setText(e.target.value);

  const changeSearchBy = (e) => {
    setSearchBy(e.target.value);
    setText("");
    setShowText(false);
  };

  return (
    <Fragment>
      <div className="right-content-wraper">
        <div className="container">
          <div className="s12 col">
            <div className="content-block bottom-shadow">
              <div className="p-15">
                <div className="row mb-0">
                  <div className="s4 col">
                    <div className="page-title">
                      <h3>Search User</h3>
                      <div className="sub-title d-none">
                        Search User by Mobile Number or Email Id{" "}
                      </div>
                    </div>
                  </div>

                  <div className="s4 col">
                    <div className="control-capabilities">
                      <div className="model-srch reset">
                        <label className="field-name active">Search By</label>
                        <select
                          className="custom-select select-reset radius-20"
                          name="searchBy"
                          value={searchBy}
                          onChange={(e) => changeSearchBy(e)}
                        >
                          <option value="mobile">Mobile No</option>
                          <option value="email">Email Id</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="s4 col">
                    <form className="from" onSubmit={onSubmit}>
                      <div
                        className={
                          searchBy === "mobile"
                            ? "search-device not-found right no-left-pad"
                            : "search-device not-found right"
                        }
                      >
                        {searchBy === "mobile" && (
                          <PhoneInput
                            // country={""}
                            enableSearch={false}
                            value={code}
                            onChange={setCode}
                            onlyCountries={allowedCountries}
                            containerClass="country-code-container"
                            searchClass="country-code-search"
                            dropdownClass="country-code-dropdown"
                          />
                        )}
                        {searchBy !== "mobile" && (
                          <i className="material-icons search-icon"> search</i>
                        )}
                        <input
                          className="add-input md "
                          placeholder={
                            searchBy === "mobile"
                              ? "Mobile No without country code"
                              : "Enter Email Id"
                          }
                          type="text"
                          name="text"
                          value={text}
                          onChange={onChange}
                        />
                        <input
                          type="submit"
                          value="Search"
                          className="btn orange-bg btn-block md no-shadow"
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Alert />
          {showText && user ? <SearchUser user={user} /> : null}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  tenant: state.tenant,
  allowedCountries: state.auth.allowedCountries,
});

export default connect(mapStateToProps, (dispatch) => ({
  getCurrentUser: getCurrentUser(dispatch),
}))(Dashboard);
