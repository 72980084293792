import axios from "axios";
import env from "../env";
import { GET_DEVICE_LOGS_FAILURE, GET_DEVICE_LOGS_REQUEST, GET_DEVICE_LOGS_SUCCESS } from "./types";
import { setAlert } from "./alert";

export const getDeviceDACLogsData = (params, cb = () => { }) => (dispatch) => {
    const { macAddress, ...parameters } = params;
    if (!/^([0-9A-Fa-f]{2}[:]){5}[0-9A-Fa-f]{2}$/.test(macAddress)) {
        dispatch(setAlert(("Please enter a valid MAC Address"), "danger"));
        dispatch({
            type: GET_DEVICE_LOGS_FAILURE,
            payload: [],
        });
        cb();
        return;
    }
    dispatch({
        type: GET_DEVICE_LOGS_REQUEST,
    });
    axios.get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/certificateManager/dac/${macAddress}/log`, { params: parameters }).then(({ data }) => {
            dispatch({
                type: GET_DEVICE_LOGS_SUCCESS,
                payload: Array?.isArray(data) ? data : [],
            });
            cb();
        }).catch(
            (err) => {
                cb();
                dispatch(setAlert((err?.response?.data?.message ?? "Failed to fetch device DAC data."), "danger"));
                dispatch({
                    type: GET_DEVICE_LOGS_FAILURE,
                    payload: [],
                });
            })
}

export const getDACCount = (params, cb = () => { }) => (dispatch) => {
    dispatch({
        type: GET_DEVICE_LOGS_REQUEST,
    });
    axios.get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/certificateManager/dac/count`,
        { params: params }).then(({ data }) => {
            dispatch({
                type: GET_DEVICE_LOGS_SUCCESS,
                payload: data,
            });
            cb();
        }).catch(
            (err) => {
                cb();
                dispatch(setAlert((err?.response?.data?.message ?? "Failed to fetch device DAC data."), "danger"));
                dispatch({
                    type: GET_DEVICE_LOGS_FAILURE,
                    payload: {},
                });
            })
}

export const getDeviceLogsData = (deviceId) => {
    return new Promise((resolve, reject) => {
        axios
            .get(
                `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/device/${deviceId}/logs`
            )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
};

export const getDeviceConnDisconnLogsData = (deviceId, cb = () => { }) => (dispatch) => {
    dispatch({
        type: GET_DEVICE_LOGS_REQUEST,
    });
    axios.get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/device/${deviceId}/connDisconnLogs`).then(({ data }) => {
            dispatch({
                type: GET_DEVICE_LOGS_SUCCESS,
                payload: Array?.isArray(data) ? data : [],
            });
            cb();
        }).catch(
            (err) => {
                cb();
                dispatch(setAlert((err?.response?.data?.message ?? "Failed to fetch Connection status data.") + "- Device ID: " + deviceId, "danger"));
                dispatch({
                    type: GET_DEVICE_LOGS_FAILURE,
                    payload: [],
                });
            })
}


export const getDeviceDiagnosticsData = (params, cb = () => { }) => (dispatch) => {
    let { deviceId, ...args } = params
    const config = {
        params: args
    };
    dispatch({
        type: GET_DEVICE_LOGS_REQUEST,
    });
    axios.get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/device/${deviceId}/deviceDiagnosticsLogs`, config).then(({ data }) => {
            dispatch({
                type: GET_DEVICE_LOGS_SUCCESS,
                payload: Array?.isArray(data) ? data : [],
            });
            cb();
        }).catch(
            (err) => {
                dispatch(setAlert((err?.response?.data?.message ?? "Failed to fetch device diagnostic data.") + "- Device ID: " + deviceId, "danger"));
                dispatch({
                    type: GET_DEVICE_LOGS_FAILURE,
                    payload: [],
                });
                cb();
            })
}

export const downloadDeviceDiagnosticReport = (filteredParams, cb = () => { }) => (dispatch) => {
    let { printableStartDate, deviceId, ...params } = filteredParams
    axios
        .get(
            `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/device/${deviceId}/deviceDiagnosticsFile`,
            {
                params: params,
            }
        )
        .then((res) => {
            const blob = new Blob([res.data], { type: "text/csv" });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = `Device_Diagnostic_${deviceId}_${printableStartDate}`;
            a.click();
            cb();
            dispatch(setAlert(("Downloaded device diagnostic data: ") + printableStartDate + " - Device ID: " + deviceId, "success"));
        }).catch((err) => {
            cb();
            dispatch(setAlert((err?.response?.data?.message ?? "Failed to download device diagnostic data") + "- Device ID: " + deviceId, "danger"));
        })
};


export const downloadDeviceStatusReport = (filteredParams, cb = () => { }) => (dispatch) => {
    let { printableStartDate, deviceId, ...params } = filteredParams
    axios
        .get(
            `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/device/${deviceId}/deviceStatusFile`,
            {
                params: params,
            }
        )
        .then((res) => {
            const blob = new Blob([res.data], { type: "text/csv" });
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = `Device_Status_${deviceId}_${printableStartDate}`;
            a.click();
            cb();
            dispatch(setAlert(("Downloaded device status data: ") + printableStartDate + " - Device ID: " + deviceId, "success"));
        }).catch((err) => {
            cb();
            dispatch(setAlert((err?.response?.data?.message ?? "Failed to download device status data.") + "- Device ID: " + deviceId, "danger"));
        })
};


export const getDeviceStatusData = (params, cb = () => { }) => (dispatch) => {
    let { deviceId, ...args } = params
    const config = {
        params: args
    };
    dispatch({
        type: GET_DEVICE_LOGS_REQUEST,
    });
    axios.get(
        `${env.GET_DEVICEMANAGEMENT_API_URL()}/simplifi/v1/deviceservices/device/${deviceId}/deviceStatusLogs`, config).then(({ data }) => {
            dispatch({
                type: GET_DEVICE_LOGS_SUCCESS,
                payload: Array?.isArray(data) ? data : [],
            });
            cb();
        }).catch(
            (err) => {
                dispatch(setAlert((err?.response?.data?.message ?? "Failed to fetch device status data.") + "- Device ID: " + deviceId, "danger"));
                dispatch({
                    type: GET_DEVICE_LOGS_FAILURE,
                    payload: [],
                });
                cb();
            })
}