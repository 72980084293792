import React, { Fragment, useState, useEffect, useRef } from "react";
import { makeStyles } from '@material-ui/core/styles';
import M from "materialize-css/dist/js/materialize.min.js";
import DeviceDealerList from "./deviceDealersList";
import { getCountryCodeISO } from "../../utils/general";
import { getDeviceDealerInfoByPSN, getDeviceDealerInfoByDealerId } from "../../action/deviceDealer";
import DisplayDeviceDealerDeatils from "./deviceDealerDetailsPage";
import Alert from "../layout/Alert";
import customConfig from "../../config";

const useStyles = makeStyles({
    selectInput: {
        height: "3rem",
        width: "100%"
    },
    selectValues: {
        width: "100%",
    },
    activeTab: {
        border: '1px solid #bdbdbd',
        backgroundColor: '#e0e0e0',
        textTransform: 'capitalize'
    },
    nonActiveTab: {
        border: 'none',
        backgroundColor: 'none',
        textTransform: 'capitalize'
    },
});

const GetDealerInfo = ({
    allowedDeviceCategories,
    allowedCompanyBrand,
    allowedCountries,
    setAlert,
    onEditDeviceDealerDetails,
    showUpdatedDealerInfo,
    userRoleInfo
}) => {

    const classes = useStyles()

    const [dealerSearchBy, setDealerSearchBy] = useState("dealerId")
    const [dealerSearchByID, setDealerSearchByID] = useState("")
    const [searchButtonClicked, setSearchButtonClicked] = useState("")
    const [dealerSearchByCountry, setDealerSearchByCountry] = useState("Select Country")
    const [dealerSearchByPSN, setDealerSearchByPSN] = useState("")

    const [deviceDealerInfo, setDeviceDealerInfo] = useState({})

    useEffect(() => {
        M.AutoInit()
        setDeviceDealerInfo({})
    }, [dealerSearchBy, dealerSearchByCountry, dealerSearchByID, dealerSearchByPSN, searchButtonClicked])

    useEffect(() => {
        if (showUpdatedDealerInfo) {
            setDeviceDealerInfo({})
        }
    }, [showUpdatedDealerInfo])

    function handleEditDeviceDealerDetails(deviceDealerDetails) {
        onEditDeviceDealerDetails(deviceDealerDetails)
    }

    function handleSearchButtonClicked() {
        setSearchButtonClicked(true)

        if (dealerSearchBy == "productSerialNumber") {
            if (dealerSearchByPSN && dealerSearchByPSN.length != 0) {
                getDeviceDealerInfoByPSN(dealerSearchByPSN)
                    .then((data) => {
                        setDeviceDealerInfo(data)
                    })
                    .catch((err) => {
                        setAlert(err?.response?.data?.message ?? "Failed to Get Dealer Details", "danger")
                    })
            }
            else {
                setAlert("Invalid Product Serial Number", "danger")
            }
        }
        else if (dealerSearchBy == "dealerId") {
            if (dealerSearchByID && dealerSearchByID.length != 0) {
                getDeviceDealerInfoByDealerId(dealerSearchByID)
                    .then((data) => {
                        setDeviceDealerInfo(data)
                    })
                    .catch((err) => {
                        setAlert(err?.response?.data?.message ?? "Failed to Get Dealer Details", "danger")
                    })
            }
            else {
                setAlert("Invalid Dealer ID", "danger")
            }
        }
        else {
            // search is by country.. there we have to display the dealer list instead of single dealer
            if (!dealerSearchByCountry || (dealerSearchByCountry && dealerSearchByCountry == "Select Country") ||
                (dealerSearchByCountry && dealerSearchByCountry.length == 0)
            ) {
                setAlert("Invalid Country", "danger")
            }
        }
    }

    function handleDeleteDeviceDealerDetails() {
        setDeviceDealerInfo({})
    }

    return (
        <Fragment>
            <div className="row">
                <Alert />
                <div className="content-block bottom-shadow mb-0">
                    <div className="p-15">
                        <div className="row reset-device-block mb-0">
                            <div className="s4 col ">
                                <div className="page-title">
                                    <label className="black-text font-600">
                                        Search Device Dealer
                                    </label>
                                </div>
                            </div>
                            <div className="s4 col">
                                <form className="from">
                                    <div className="control-capabilities">
                                        <div className="model-srch reset">
                                            <label className="field-name active">
                                                Search By
                                            </label>
                                            <select
                                                className="custom-select select-reset"
                                                name="dealerSearchBy"
                                                value={dealerSearchBy}
                                                onChange={(e) => {
                                                    setSearchButtonClicked(false)
                                                    setDealerSearchBy(e.target.value)
                                                }}
                                            >
                                                <option value="dealerId" key="dealerId">Dealer Id</option>
                                                <option value="productSerialNumber" key="productSerialNumber">Product Serial Number</option>
                                                <option value="dealerCountry" key="dealerCountry">Country</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="s4 col">
                                <div className="search-device not-found right">
                                    {
                                        dealerSearchBy == "dealerId" && <Fragment>
                                            <i className="material-icons search-icon"> search</i>
                                            <input
                                                className="add-input md border-0 "
                                                placeholder="Enter Dealer Id"
                                                type="text"
                                                name="dealerSearchField"
                                                value={dealerSearchByID}
                                                onChange={(e) => {
                                                    setDealerSearchByID(e.target.value)
                                                    setSearchButtonClicked(false)
                                                }}
                                            />
                                        </Fragment>
                                    }
                                    {
                                        dealerSearchBy == "productSerialNumber" && <Fragment>
                                            <i className="material-icons search-icon"> search</i>
                                            <input
                                                className="add-input md border-0 "
                                                placeholder="Enter Product Serial Number"
                                                type="text"
                                                name="dealerSearchField"
                                                value={dealerSearchByPSN}
                                                onChange={(e) => {
                                                    setDealerSearchByPSN(e.target.value)
                                                    setSearchButtonClicked(false)
                                                }}
                                            />
                                        </Fragment>
                                    }
                                    {
                                        dealerSearchBy == "dealerCountry" && <Fragment>
                                            <div className="model-srch reset width-md">
                                                <select
                                                    className="custom-select select-reset radius-20"
                                                    style={{ "display": "block !important" }}
                                                    onChange={(e) => {
                                                        setDealerSearchByCountry(e.target.value)
                                                        setSearchButtonClicked(false)
                                                    }}
                                                    value={dealerSearchByCountry}
                                                >
                                                    {["Select Country", ...allowedCountries].filter((country) => getCountryCodeISO(country.toUpperCase())).map((element, index) => (
                                                        index == 0 ? <option value="Select Country">Select Country</option> :
                                                            <option value={getCountryCodeISO(element.toUpperCase())}>{getCountryCodeISO(element.toUpperCase())}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Fragment>
                                    }
                                    <input
                                        type="button"
                                        value="Search"
                                        className="btn orange-bg btn-block md no-shadow"
                                        onClick={() => {
                                            handleSearchButtonClicked()
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mid-seprator"></div>
            {

                searchButtonClicked && dealerSearchBy == "dealerCountry" && dealerSearchByCountry && dealerSearchByCountry != "Select Country" && dealerSearchByCountry.length > 0 &&
                <DeviceDealerList
                    countryCodeFilter={dealerSearchByCountry}
                    setAlert={setAlert}
                    onEditDeviceDealerDetails={handleEditDeviceDealerDetails}
                    showUpdatedDealerInfo={showUpdatedDealerInfo}
                    userRoleInfo={userRoleInfo}
                >
                </DeviceDealerList>
            }
            {
                searchButtonClicked && dealerSearchBy == "productSerialNumber" && dealerSearchByPSN && dealerSearchByPSN.length > 0 &&
                deviceDealerInfo && Object.keys(deviceDealerInfo).length > 0 &&
                <DisplayDeviceDealerDeatils
                    deviceDealerDetails={deviceDealerInfo}
                    displayEditDeleteButton={
                        (userRoleInfo.level > 4 || userRoleInfo.roles.includes(customConfig.roleCloudAdmin)) ? true : false
                    }
                    onEditDeviceDealerDetails={handleEditDeviceDealerDetails}
                    showUpdatedDealerInfo={showUpdatedDealerInfo}
                    onDeleteDeviceDealerDetails={handleDeleteDeviceDealerDetails}
                    setAlert={setAlert}
                >
                </DisplayDeviceDealerDeatils>
            }
            {

                searchButtonClicked && dealerSearchBy == "dealerId" && dealerSearchByID && dealerSearchByID.length > 0 &&
                deviceDealerInfo && Object.keys(deviceDealerInfo).length > 0 &&
                <DisplayDeviceDealerDeatils
                    deviceDealerDetails={deviceDealerInfo}
                    displayEditDeleteButton={
                        (userRoleInfo.level > 4 || userRoleInfo.roles.includes(customConfig.roleCloudAdmin)) ? true : false
                    }
                    onEditDeviceDealerDetails={handleEditDeviceDealerDetails}
                    onDeleteDeviceDealerDetails={handleDeleteDeviceDealerDetails}
                    setAlert={setAlert}
                >
                </DisplayDeviceDealerDeatils>
            }

        </Fragment>
    );
}

export default GetDealerInfo

