export const getDeviceLogPermission = (userRoles, operation, type = "role", userLevel) => {
    const roleBasedpermissions = {
        deviceInfo: true,
        dacLogs: userRoles?.includes("FACTORYADMIN") || userRoles?.includes("CUSTOMERSUPPORTL4") || userRoles?.includes("CLOUDADMIN"),
        deviceLogs: true,
        connectionStatusLogs: userRoles?.includes('FACTORYADMIN') || userRoles?.includes('CUSTOMERSUPPORTL2') || userRoles?.includes('CUSTOMERSUPPORTL3') || userRoles?.includes('CUSTOMERSUPPORTL4') || userRoles?.includes('CLOUDADMIN') || userRoles?.includes('CUSTOMERSUPPORTL3SPECIAL'),
        diagnosticLogs: userRoles?.includes('FACTORYADMIN') || userRoles?.includes('CUSTOMERSUPPORTL2') || userRoles?.includes('CUSTOMERSUPPORTL3') || userRoles?.includes('CUSTOMERSUPPORTL4') || userRoles?.includes('CLOUDADMIN') || userRoles?.includes('CUSTOMERSUPPORTL3SPECIAL'),
        statusLogs: userRoles?.includes('FACTORYADMIN') || userRoles?.includes('CUSTOMERSUPPORTL2') || userRoles?.includes('CUSTOMERSUPPORTL3') || userRoles?.includes('CUSTOMERSUPPORTL4') || userRoles?.includes('CLOUDADMIN') || userRoles?.includes('CUSTOMERSUPPORTL3SPECIAL'),
    }
    const levelBasedPermissions = {
    }
    return type === 'level' ? levelBasedPermissions?.hasOwnProperty(operation) ? levelBasedPermissions[operation] : false : roleBasedpermissions?.hasOwnProperty(operation) ? roleBasedpermissions[operation] : false
}