import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, MFA_QR_CODE, MFA_ENABLE } from "../action/types";

const initialState = {
  isAuthenticated: null,
  loading: true,
  user: null,
  qrCodeUrl: null,
  mfaToken: null
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:

      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        token: payload.accessToken,
      };

    case MFA_QR_CODE:
      return {
        ...state,
        isAuthenticated: false,
        qrCodeUrl: payload.qrCodeUrl, 
        loading: false,
      };
    case MFA_ENABLE:
      return {
        ...state,
        mfaToken: payload.mfaToken,
      }
    case LOGIN_FAIL:
    case LOGOUT:
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
      };

    default:
      return state;
  }
}
